import { render, staticRenderFns } from "./Oferta_pdf.vue?vue&type=template&id=2f758560&"
import script from "./Oferta_pdf.vue?vue&type=script&lang=js&"
export * from "./Oferta_pdf.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports