
<template>

        <object :data="Constants.ServerUrl + 'oferte/oferta_pdf/' + IdOferta" type="application/pdf" width="100%" :height="parentHeight">
            <p><a :href="Constants.ServerUrl + 'oferte/oferta_pd/' + IdOferta"></a></p>
        </object>                  
                
</template>

<script>
import settings from "@/backend/LocalSettings"
import BasePage from '@/pages/BasePage' 
import Constants from '@/backend/Constants'
export default {
    name: "oferta_pdf",
    extends: BasePage,

    data(){
        return {
            Constants: Constants,
            IdOferta: 0,   
            parentHeight: 1000,        
        }
    },
    methods: {
        genereaza() {  
            this.IdOferta = this.$route.params.oferta    
        }
    },
    mounted() {
        this.genereaza()
    }
}
</script>

